.toastWrapper {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px;
  min-width: 250px;
  min-height: 200px;

  .toast {
    position: fixed;
    width: 100%;
    right: 0;
    z-index: 100;
    margin: 15px;
  }
}