.navbar  {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.username {
    color: white;
}

